<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow2-a" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex align-center mb-2">
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="shadow-off mr-2" small icon @click="$nova.gotoLink({path: '/bookclosure/list'})"><v-icon>mdi-arrow-left</v-icon></v-btn>
              </template>
              <span>Back to Analysis List</span>
            </v-tooltip>
            <div>
              <v-chip v-if="stage === 3" small label color="success">Closed</v-chip>
              <v-chip v-else-if="stage === 1" small label color="warning">Pending for review</v-chip>
              <v-chip v-else small label color="info">Open</v-chip>
            </div>
            <v-spacer></v-spacer>
            <v-btn class="shadow-off" small text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
          </div>
          <div class="d-flex align-center">
            <div v-if="checklist.length > 0" class="mr-4">
              <v-progress-circular
                :size="55" :width="10" color="success"
                :value="($nova.formatNumber(((summary.approved || 0)/(summary.count || 0)) || 0))*100"
              >
                {{ ((((summary.approved || 0)/(summary.count || 0)) || 0)*100).toFixed(0) }}%
              </v-progress-circular>
            </div>
            <div>
              <div class="d-flex align-center">
                <div class="subtitle-1 font-weight-bold mr-2">{{metadata.description}}</div>
              </div>
              <div>
                <span class="mr-2"><v-icon class="mr-1">mdi-calendar-range</v-icon> Period: </span>
                <span class="mr-1">{{$nova.formatDate(metadata.from)}}</span>
                <span class="mr-1">- {{$nova.formatDate(metadata.to)}}</span>
              </div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="">
            <div class="">
              <v-tabs v-model="tab" height="40">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab key="0" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-format-list-bulleted</v-icon>Checklist</v-tab>
                <v-tab key="1" v-if="checklist.length > 0" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-format-list-bulleted</v-icon>Trial Balance</v-tab>
                <v-tab key="2" v-if="checklist.length > 0" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-format-list-bulleted</v-icon>Information Workflow</v-tab>
              </v-tabs>
            </div>
            <v-divider></v-divider>
            <div class="flex-grow-1 d-block">
              <v-tabs-items v-model="tab" class="">
                <v-tab-item key="0" eager>
                  <v-card class="shadow-off">
                    <v-card-text class="px-0" v-if="checklist.length > 0">
                      <div class="d-flex align-center" v-if="checklist.length > 0">
                        <div class="d-flex align-center mb-2 primary--text mr-4">
                          <span class="display-1">{{summary.count || 0}}</span>
                          <span class="ml-1 line-1">Total<br/>Count</span>
                        </div>
                        <div class="d-flex align-center mb-2 success--text mx-4">
                          <span class="display-1">{{summary.approved || 0}}</span>
                          <span class="ml-1 line-1">Approved<br/>Count</span>
                        </div>
                        <div class="d-flex align-center mb-2 info--text mx-4">
                          <span class="display-1">{{summary.pending || 0}}</span>
                          <span class="ml-1 line-1">Awaiting Submission<br/>Count</span>
                        </div>
                        <div class="d-flex align-center mb-2 warning--text mx-4">
                          <span class="display-1">{{summary.pendingapproval || 0}}</span>
                          <span class="ml-1 line-1">Awaiting Approval<br/>Count</span>
                        </div>
                        <v-spacer></v-spacer>
                      </div>
                      <div class="d-flex align-center mb-4">
                        <div class="font-weight-bold mr-4 body-2">Filter: </div>
                        <v-checkbox label="Pending" class="mt-0 mr-4 info--text" dense hide-details color="info" v-model="stagefilter" multiple :value="0"></v-checkbox>       
                        <v-checkbox label="Pending Approval" class="mt-0 mr-4 warning--text" dense hide-details color="warning" v-model="stagefilter" multiple :value="2"></v-checkbox>       
                        <v-checkbox label="Closed" class="mt-0 mr-4 success--text" dense hide-details color="success" v-model="stagefilter" multiple :value="3"></v-checkbox>       
                        <v-spacer></v-spacer>
                        <v-tooltip left content-class="tooltip-left" v-if="multiselectdata.length > 0 && metadata.stage === 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon small  class="" @click="bulksubmit=true;approvecommentarydialog=true;selectedcommentary={}" color="success"><v-icon>mdi-check-circle</v-icon></v-btn>
                          </template>
                          <span>Approve all Selected</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-if="multiselectdata.length > 0 && metadata.stage === 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon small  class="" @click="bulksubmit=true;rejectcommentarydialog=true;selectedcommentary={}" color="error"><v-icon>mdi-close-circle</v-icon></v-btn>
                          </template>
                          <span>Reject all Selected</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="" @click="commentsdialog=true" v-bind="attrs" v-on="on" icon small>
                              <v-icon class="">mdi-comment-text-multiple</v-icon>
                            </v-btn>
                          </template>
                          <span>Conversations</span>
                        </v-tooltip>
                        <div class="line-1 ml-4">
                          <div class="d-flex align-center">
                            <v-avatar size="8" color="warning"></v-avatar>
                            <span class="ml-2">Approver</span>
                          </div>
                          <div class="d-flex align-center">
                            <v-avatar size="8" color="info"></v-avatar>
                            <span class="ml-2">Preparer</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <v-simple-table dense class="">
                          <template v-slot:default>
                            <thead>
                              <th class="text-center" v-if="metadata.stage === 0"></th>
                              <th class="text-left">Task Description</th>
                              <th class="text-center"></th>
                              <th class="text-center">Information Workflow Status</th>
                              <th class="text-right">Amount as per Trial Balance</th>
                              <th class="text-right">Amount as per Schedule</th>
                              <th class="text-right">Difference</th>
                              <th class="text-center">Action Centre</th>
                              <th class="text-center">
                                Status<br/>
                                <v-btn icon small @click="statushelpdialog=true"><v-icon>mdi-help-circle</v-icon></v-btn>
                              </th>
                            </thead>
                            <v-slide-y-transition class="" group tag="tbody">
                              <tr v-for="(v,k) in formattedchecklistdata" :key="k" class="">
                                <td v-if="v.type==='category'" colspan="9" class="py-2 font-weight-bold body-2 border-left-caccounting" :style="`background-color:${$nova.hexToRgbA($vuetify.theme.currentTheme.caccounting, 0.1)}`">
                                  {{v.name}}
                                </td>
                                <template v-else>
                                  <td class="text-center" v-if="metadata.stage === 0">
                                    <v-checkbox v-if="(v.approver || []).indexOf(userid) > -1 && v.stage === 2" multiple v-model="multiselectdata" :value="v._id" hide-details dense class="pa-0 ma-0"></v-checkbox>
                                  </td>
                                  <td class="">
                                    <span class="font-weight-bold">{{ v.tag }}</span>
                                    <span class=""><v-icon>mdi-circle-small</v-icon></span>
                                    {{v.description}}
                                  </td>
                                  <td class="text-center">
                                    <v-avatar size="8" color="warning" v-if="(v.approver || []).indexOf(userid) > -1"></v-avatar>
                                    <v-avatar size="8" color="info" v-else-if="(v.preparer || []).indexOf(userid) > -1"></v-avatar>
                                  </td>
                                  <td class="text-center">
                                    
                                    <div v-if="((v.__referencesummary || {}).total || 0) > 0">
                                      <v-tooltip bottom content-class="tooltip-bottom">
                                        <template v-slot:activator="{ on, attrs }">
                                          <div class="my-1 cursor-pointer" @click.stop="populatepbclinkdialogitems(v)" v-bind="attrs" v-on="on">
                                            {{ (((((v.__referencesummary || {}).completed || 0)/((v.__referencesummary || {}).total || 0)) || 0)*100).toFixed(0) }}%
                                            <v-progress-linear
                                              :height="10" color="success"
                                              :value="((((v.__referencesummary || {}).completed || 0)/((v.__referencesummary || {}).total || 0)) || 0)*100"
                                            >
                                            </v-progress-linear>
                                          </div>
                                        </template>
                                        <span>View Information Workflow List</span>
                                      </v-tooltip>
                                    </div>
                                    <span v-else>NA</span>
                                  </td>
                                  <td class="text-right">{{$nova.formatNumber((tbtotals[v.tag] || 0).toFixed(0))}}</td>
                                  <td class="text-right" v-if="Number((v.result || {}).value)">{{$nova.formatNumber((Number((v.result || {}).value) || 0).toFixed(0))}}</td>
                                  <td class="text-right" v-else>NA</td>
                                  <td class="text-right error--text">{{$nova.formatNumber(((tbtotals[v.tag] || 0) - (Number((v.result || {}).value) || 0)).toFixed(0))}}</td>
                                  <td class="text-center">
                                    <div class="d-flex align-center justify-center">
                                      <v-tooltip v-if="v.stage === 2 && (v.approver || []).indexOf(userid) > -1" bottom content-class="tooltip-bottom">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn @click.stop="changeChecklistStatus(v._id, 'approve')" color="success" v-bind="attrs" v-on="on" icon small><v-icon>mdi-check-circle</v-icon></v-btn>
                                        </template>
                                        <span>Approve</span>
                                      </v-tooltip>
                                      <v-tooltip v-if="v.stage === 2 && (v.approver || []).indexOf(userid) > -1" bottom content-class="tooltip-bottom">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn @click="changeChecklistStatus(v._id, 'reject')" color="error" v-bind="attrs" v-on="on" icon small><v-icon>mdi-close-circle</v-icon></v-btn>
                                        </template>
                                        <span>Reject</span>
                                      </v-tooltip>
                                      <!-- <v-tooltip left content-class="tooltip-left">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon v-bind="attrs" v-on="on">mdi-help-rhombus</v-icon>
                                        </template>
                                        <span>Contact Consark</span>
                                      </v-tooltip> -->
                                      <lb-file v-model="files[v._id]" label=""
                                        :loading="fileprocessing[v._id] === 1" :loadingpercent="(Number(fileprocessingpercent[v._id]) || 0) + (counter*0)" 
                                        :drag="false" hidedetails @change="uploadFile(v._id)" :displayname="false"
                                        v-if="(v.preparer || []).indexOf(userid) > -1"
                                        accept=".xlsx,.xls,.xlsm,.xlsb" :disabled="stage !== 0"
                                      />
                                      <v-btn v-if="v.upload" class="mt-1 black--text" icon small @click="$nova.downloadFile(v.upload)"><v-icon>mdi-download</v-icon></v-btn>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <v-tooltip left content-class="tooltip-left" v-if="v.stage === 0">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                                      </template>
                                      <span>Pending Submission</span>
                                    </v-tooltip>
                                    <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="warning">mdi-cogs</v-icon>
                                      </template>
                                      <span>Processing</span>
                                    </v-tooltip>
                                    <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                                      </template>
                                      <span>Awaiting approval</span>
                                    </v-tooltip>
                                    <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 3">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                      </template>
                                      <span>Closed</span>
                                    </v-tooltip>
                                  </td>
                                </template>
                              </tr>
                            </v-slide-y-transition>
                          </template>
                        </v-simple-table> 
                      </div>
                    </v-card-text>
                    <v-card-text v-else class="pa-0">
                      <div class="d-flex align-center pa-2 hoverable">
                        <span class="mr-4">Book Closure Checklist</span>
                        <lb-file v-model="checklistfile" label="" class="py-0 my-0"
                          :loading="fileprocessing['__checklist'] === 1"  :drag="false" hidedetails @change="readChecklistFile('__checklist')" :displayname="false"
                          accept=".xlsx,.xls,.xlsm,.xlsb"/>
                        <v-spacer></v-spacer>
                        <v-chip v-if="checklist.length === 0" color="info" label small>Pending</v-chip>
                        <v-chip v-else color="success" label small>Uploaded</v-chip>
                      </div>
                      <v-divider></v-divider>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item key="1" eager>
                  <v-card class="shadow-off custom-datatable-header">
                    <v-card-text class="px-0 pt-0" v-if="tb.length > 0">
                      <div class="d-flex align-center pa-2" v-if="metadata.stage===0">
                        <span class="mr-4">Trial Balance Changed?</span>
                        <lb-file v-model="tbfile" label="" class="py-0 my-0"
                          :loading="fileprocessing['__tb'] === 1"  :drag="false" hidedetails @change="readTBFile('__tb')" :displayname="false"
                          accept=".xlsx,.xls,.xlsm,.xlsb"/>
                        <v-spacer></v-spacer>
                        <v-chip v-if="tb.length === 0" color="info" label small>Pending</v-chip>
                        <v-chip v-else color="success" label small>Uploaded</v-chip>
                      </div>
                      <v-data-table
                        dense
                        :headers="tbtableheader"
                        :items="formattedtbtabledata"
                        :items-per-page="-1"
                        class="elevation-1"
                        hide-default-footer
                      >
                        <template v-slot:[`item.py_amount`]="{ item }">
                          <div class="text-right">{{$nova.formatNumber(item.py_amount)}}</div>
                        </template>
                        <template v-slot:[`item.transaction`]="{ item }">
                          <div class="text-right">{{$nova.formatNumber(item.transaction)}}</div>
                        </template>
                        <template v-slot:[`item.audit_entries`]="{ item }">
                          <div class="text-right">{{$nova.formatNumber(item.audit_entries)}}</div>
                        </template>
                        <template v-slot:[`item.cy_amount`]="{ item }">
                          <div class="text-right">{{$nova.formatNumber(item.cy_amount)}}</div>
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <v-card-text v-else class="pa-0">
                      <div class="d-flex align-center pa-2">
                        <span class="mr-4">Trial Balance</span>
                        <lb-file v-model="tbfile" label="" class="py-0 my-0"
                          :loading="fileprocessing['__tb'] === 1"  :drag="false" hidedetails @change="readTBFile('__tb')" :displayname="false"
                          accept=".xlsx,.xls,.xlsm,.xlsb"/>
                        <v-spacer></v-spacer>
                        <v-chip v-if="tb.length === 0" color="info" label small>Pending</v-chip>
                        <v-chip v-else color="success" label small>Uploaded</v-chip>
                      </div>
                      <v-divider></v-divider>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item key="2" eager>
                  <pbc v-if="metadata.pbcproject && !loading" :embed="true" api="/v2/bookclosure/pbc/" @pbcdata="populatepbccount" :reflist="formattedreflist" :embedid="metadata.pbcproject"/>
                  <div v-else>
                    <div class="d-flex align-center pa-2 hoverable">
                      <span class="mr-4">Information Workflow Checklist</span>
                      <lb-file v-model="pbcfile" label="" class="py-0 my-0"
                        :loading="fileprocessing['__pbc'] === 1"  :drag="false" hidedetails @change="readPbcFile('__pbc')" :displayname="false"
                        accept=".xlsx,.xls,.xlsm,.xlsb"/>
                      <v-spacer></v-spacer>
                      <v-chip v-if="!metadata.pbcproject" color="info" label small>Pending</v-chip>
                      <v-chip v-else color="success" label small>Uploaded</v-chip>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>

        </v-card-text>
      </v-card>

      <v-dialog v-model="statushelpdialog" max-width="600" persistent>
        <v-card class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Status Notations</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="statushelpdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="pa-4">
            <div class="my-1">
              <v-icon color="info">mdi-timer-sand</v-icon>
              <span class="ml-2">Pending Submission</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="warning">mdi-cogs</v-icon>
              <span class="ml-2">Processing</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="warning">mdi-timer-sand</v-icon>
              <span class="ml-2">Awaiting approval</span>
            </div>
            <v-divider></v-divider>
            <div class="my-1">
              <v-icon color="success">mdi-check-circle</v-icon>
              <span class="ml-2">Closed</span>
            </div>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-dialog>
      
      <v-dialog v-model="checklistdialog" persistent>
        <v-card class="" loader-height="2" :loading="checklistloading" :disabled="checklistloading">
          <v-card-text class="pt-6">
            <div class="d-flex align-center mb-4">
              <div class="title">Checklist upload</div>
              <v-spacer></v-spacer>
              <v-btn small class="ml-2" color="primary" v-if="!checklisterrorflag" @click="uploadChecklistData()">Verify & Upload</v-btn>
              <div class="ml-2 error--text font-weight-bold" v-else>We found few errors. Rectify the same to proceed to upload</div>
              <v-btn icon @click="checklistdialog = false" class="ml-2"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <perfect-scrollbar style="max-height: 70vh" v-if="checklistuploaddata.length > 0">
              <div>
                <v-simple-table dense class="">
                  <template v-slot:default>
                    <thead>
                      <th scope="tag">Tag</th>
                      <th scope="category">Category</th>
                      <th scope="subcategory">Sub Category</th>
                      <th scope="preparer">Preparer</th>
                      <th scope="approver">Approver</th>
                      <th style="white-space: break-spaces;" class="">Description</th>
                    </thead>
                    <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                      <tr v-for="(v,k) in checklistuploaddata" :key="k" class="">
                        <td v-if="((checklistdataerror[k] || [])[0] || [])[0] !== ''" class="error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip v-bind="attrs" v-on="on" color="error" small outlined class="pl-0 mb-1 mr-1">
                                <v-avatar color=""><v-icon>mdi-tag</v-icon></v-avatar>
                                <span class="pl-1">{{(v.data[0] || {}).tag || 'Error'}}</span>
                              </v-chip>
                            </template>
                            <span>{{((checklistdataerror[k] || [])[0] || [])[0]}}</span>
                          </v-tooltip>
                        </td>
                        <td v-else>
                          <v-chip small outlined class="pl-0 mb-1 mr-1">
                            <v-avatar color=""><v-icon>mdi-tag</v-icon></v-avatar>
                            <span class="pl-1">{{(v.data[0] || {}).tag}}</span>
                          </v-chip>
                        </td>
                        <td v-if="((checklistdataerror[k] || [])[0] || [])[2] !== ''" class="error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).category || 'Error'}}</div>
                            </template>
                            <span>{{((checklistdataerror[k] || [])[0] || [])[0]}}</span>
                          </v-tooltip>
                        </td>
                        <td v-else>{{(v.data[0] || {}).category}}</td>
                        <td v-if="((checklistdataerror[k] || [])[0] || [])[3] !== ''" class="error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).subcategory || 'Error'}}</div>
                            </template>
                            <span>{{((checklistdataerror[k] || [])[0] || [])[0]}}</span>
                          </v-tooltip>
                        </td>
                        <td v-else>{{(v.data[0] || {}).subcategory}}</td>
                        <td class="">
                          <div class="my-1">
                            <v-chip small outlined v-for="(pv,pk) in ((v.data[0] || {}).preparer || [])" :key="pk" class="pl-0 mb-1 mr-1">
                              <v-avatar color=""><v-icon>mdi-account</v-icon></v-avatar>
                              <span class="pl-1">{{pv}}</span>
                            </v-chip>
                          </div>
                        </td>
                        <td class="my-1">
                          <div class="my-1">
                            <v-chip small outlined v-for="(pv,pk) in ((v.data[0] || {}).approver || [])" :key="pk" class="pl-0 mb-1 mr-1">
                              <v-avatar color=""><v-icon>mdi-account</v-icon></v-avatar>
                              <span class="pl-1">{{pv}}</span>
                            </v-chip>
                          </div>
                        </td>
                        <td v-if="((checklistdataerror[k] || [])[0] || [])[1] !== ''" class="error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).description || 'Error'}}</div>
                            </template>
                            <span>{{((checklistdataerror[k] || [])[0] || [])[0]}}</span>
                          </v-tooltip>
                        </td>
                        <td v-else>{{(v.data[0] || {}).description}}</td>
                      </tr>
                    </v-slide-y-transition> 
                  </template>
                </v-simple-table> 
              </div>
            </perfect-scrollbar>  
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="tbdialog" persistent>
        <v-card class="" loader-height="2" :loading="tbloading" :disabled="tbloading">
          <v-card-text class="pt-6">
            <div class="d-flex align-center mb-4">
              <div class="title">TB upload</div>
              <v-spacer></v-spacer>
              <v-btn small class="ml-2" color="primary" v-if="!tberrorflag" @click="uploadTBData()">Verify & Upload</v-btn>
              <div class="ml-2 error--text font-weight-bold" v-else>We found few errors. Rectify the same to proceed to upload</div>
              <v-btn icon @click="tbdialog = false" class="ml-2"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <perfect-scrollbar style="max-height: 70vh" v-if="tbuploaddata.length > 0">
              <div>
                <v-simple-table dense class="">
                  <template v-slot:default>
                    <thead>
                      <th class="text-left">Tag</th>
                      <th class="text-left">GL Code</th>
                      <th class="text-left">Name</th>
                      <th class="text-right">PY Amount</th>
                      <th class="text-right">Transaction</th>
                      <th class="text-right">Audit Entries</th>
                      <th class="text-right">CY Amount</th>
                    </thead>
                    <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                      <tr v-for="(v,k) in tbuploaddata" :key="k" class="">
                        <td v-if="((tbdataerror[k] || [])[0] || [])[0] !== ''" class="error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip v-bind="attrs" v-on="on" small color="error" outlined class="pl-0 mb-1 mr-1">
                                <v-avatar color=""><v-icon>mdi-tag</v-icon></v-avatar>
                                <span class="pl-1">{{(v.data[0] || {}).tag || 'Error'}}</span>
                              </v-chip>
                            </template>
                            <span>{{((tbdataerror[k] || [])[0] || [])[0]}}</span>
                          </v-tooltip>
                        </td>
                        <td v-else>
                          <v-chip small outlined class="pl-0 mb-1 mr-1">
                            <v-avatar color=""><v-icon>mdi-tag</v-icon></v-avatar>
                            <span class="pl-1">{{(v.data[0] || {}).tag}}</span>
                          </v-chip>
                        </td>
                        <td v-if="((tbdataerror[k] || [])[0] || [])[1] !== ''" class="error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).gl_code || 'Error'}}</div>
                            </template>
                            <span>{{((tbdataerror[k] || [])[1] || [])[0]}}</span>
                          </v-tooltip>
                        </td>
                        <td class="" v-else>{{(v.data[0] || {}).gl_code}}</td>
                        <td v-if="((tbdataerror[k] || [])[0] || [])[2] !== ''" class=" error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).name}}</div>
                            </template>
                            <span>{{((tbdataerror[k] || [])[2] || [])[1]}}</span>
                          </v-tooltip>
                        </td>
                        <td class="" v-else>{{(v.data[0] || {}).name}}</td>
                        <td class="text-right">{{$nova.formatNumber((v.data[0] || {}).py_amount)}}</td>
                        <td class="text-right">{{$nova.formatNumber((v.data[0] || {}).transaction)}}</td>
                        <td class="text-right">{{$nova.formatNumber((v.data[0] || {}).audit_entries)}}</td>
                        <td class="text-right">{{$nova.formatNumber((v.data[0] || {}).cy_amount)}}</td>
                      </tr>
                    </v-slide-y-transition>
                  </template>
                </v-simple-table> 
              </div>
            </perfect-scrollbar>  
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="pbcdialog" persistent>
        <v-card class="" loader-height="2" :loading="pbcloading" :disabled="pbcloading">
          <v-card-text class="pt-6">
            <div class="d-flex align-center mb-4">
              <div class="title">Information Workflow upload</div>
              <v-spacer></v-spacer>
              <v-btn small class="ml-2" color="primary" v-if="!pbcerrorflag" @click="uploadPbcData()">Verify & Upload</v-btn>
              <div class="ml-2 error--text font-weight-bold" v-else>We found few errors. Rectify the same to proceed to upload</div>
              <v-btn icon @click="pbcdialog = false" class="ml-2"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <perfect-scrollbar style="max-height: 70vh" v-if="pbcuploaddata.length > 0">
              <div>
                <v-simple-table dense class="">
                  <template v-slot:default>
                    <thead>
                      <th class="text-left">Tag</th>
                      <th class="text-left">Category</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Info Owner Name</th>
                      <th class="text-left">Info Owner Email</th>
                      <th class="text-left">File Upload</th>
                      <th class="text-left">File Upload Link</th>
                      <th class="text-left">Urgent</th>
                      <th class="text-left">Due Date</th>
                    </thead>
                    <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                      <tr v-for="(v,k) in pbcuploaddata" :key="k" class="">
                        <td class="">
                          <div class="my-1">
                            <v-tooltip v-if="((pbcdataerror[k] || [])[0] || [])[0] !== ''" bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <v-chip v-for="(pv,pk) in ((v.data[0] || {}).tag || [])" color="error" small outlined :key="pk" class="pl-0 mb-1 mr-1">
                                    <v-avatar color=""><v-icon>mdi-tag</v-icon></v-avatar>
                                    <span class="pl-1">{{pv}}</span>
                                  </v-chip>
                                </div>
                              </template>
                              <span>{{((pbcdataerror[k] || [])[0] || [])[0]}}</span>
                            </v-tooltip>
                            <v-chip small v-else outlined v-for="(pv,pk) in ((v.data[0] || {}).tag || [])" :key="pk" class="pl-0 mb-1 mr-1">
                              <v-avatar color=""><v-icon>mdi-tag</v-icon></v-avatar>
                              <span class="pl-1">{{pv}}</span>
                            </v-chip>
                          </div>
                        </td>
                        <td class="">{{(v.data[0] || {}).category || "Others"}}</td>
                        <td v-if="((pbcdataerror[k] || [])[1] || [])[0] !== '' && false" class="error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).description || 'Error'}}</div>
                            </template>
                            <span>{{((pbcdataerror[k] || [])[1] || [])[0]}}</span>
                          </v-tooltip>
                        </td>
                        <td class="" v-else>{{(v.data[0] || {}).description}}</td>
                        <td v-if="((pbcdataerror[k] || [])[2] || [])[1] !== '' && false" class=" error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).info_owner_name}}</div>
                            </template>
                            <span>{{((pbcdataerror[k] || [])[2] || [])[1]}}</span>
                          </v-tooltip>
                        </td>
                        <td class="" v-else>{{(v.data[0] || {}).info_owner_name}}</td>
                        <td v-if="((pbcdataerror[k] || [])[2] || [])[1] !== '' && false" class=" error--text font-weight-bold">
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">{{(v.data[0] || {}).info_owner_email}}</div>
                            </template>
                            <span>{{((pbcdataerror[k] || [])[2] || [])[1]}}</span>
                          </v-tooltip>
                        </td>
                        <td class="" v-else>{{(v.data[0] || {}).info_owner_email}}</td>
                        <td class="">{{(v.data[0] || {}).file_upload}}</td>
                        <td class="">{{(v.data[0] || {}).file_upload_link}}</td>
                        <td class="">{{(v.data[0] || {}).urgent}}</td>
                        <td class="">{{$nova.formatDate((v.data[0] || {}).due_date)}}</td>
                      </tr>
                    </v-slide-y-transition>
                  </template>
                </v-simple-table> 
              </div>
            </perfect-scrollbar>  
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="commentsdialog" max-width="600" persistent>
        <v-card :loading="commentsloading" :disabled="commentsloading" loader-height="2" class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Conversations</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="commentsdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4">
            <div v-if="conversations.length === 0" class="title text-center my-5 grey--text">
              No conversations
            </div>
            <div v-else>
              <div class="">
                <perfect-scrollbar class="comments-container">
                  <template v-for="(v,k) in conversations">
                    <div :class="`d-flex align-center ${($store.state.user.id!==v.user)?'':'flex-row-reverse text-right'}`" :key="k">
                      <div :class="`comment-text border-on radius-on px-3 py-1 my-1 ${($store.state.user.id!==v.user)?'grey lighten-2':'text-left'}`">{{v.comment}}</div>
                      <div class="mx-1 caption">
                        {{$nova.formatDate(v.created_at, "DD-MMM-YYYY, H:m", "")}}
                      </div>
                    </div>
                  </template>
                </perfect-scrollbar>
              </div>

            </div>

          </v-card-text>
          <v-card-text class="d-flex align-center"  v-if="metadata.stage === 0">
            <lb-string v-model="conversationtext" label="Write here..." :error="conversationtexterror" hidedetails/>
            <v-btn small class="ml-2" color="primary" @click="addComment()">Add</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="pbclinkdialog" max-width="600" persistent>
        <v-card class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Information Workflow Links</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="pbclinkdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4">
            <div class="subtitle-1">{{ pbclinkdialogchecklistdata.description || "" }}</div>
            <div class="d-flex align-center mb-2">
              <v-chip label small>{{ pbclinkdialogchecklistdata.tag }}</v-chip>
              <span class="ml-2">{{ pbclinkdialogchecklistdata.category }}</span>
            </div>
            <div>
              <v-chip label small text color="primary" outlined class="pl-0"><v-chip small label color="primary" class="mr-2">{{ (pbclinkdialogchecklistdata.__referencesummary || {}).total || 0 }}</v-chip> Total </v-chip>
              <v-chip label small text color="success" outlined class="pl-0 ml-2"><v-chip small label color="success" class="mr-2">{{ (pbclinkdialogchecklistdata.__referencesummary || {}).completed || 0 }}</v-chip> Completed</v-chip>
              <v-chip label small text color="info" outlined class="pl-0 ml-2"><v-chip small label color="info" class="mr-2">{{ (pbclinkdialogchecklistdata.__referencesummary || {}).pending || 0 }}</v-chip> Pending</v-chip>
              <v-chip label small text color="error" outlined class="pl-0 ml-2"><v-chip small label color="error" class="mr-2">{{ (pbclinkdialogchecklistdata.__referencesummary || {}).cancelled || 0 }}</v-chip> Cancelled</v-chip>
            </div>
            <div v-if="pbclinkdialogitems.length === 0" class="title text-center my-5 grey--text">
              No Information Workflow Items
            </div>
            <div v-else>
              <div class="mt-4">
                <div class="font-weight-bold">Request(s)</div>
                <perfect-scrollbar style="max-height: 65vh">
                  <div v-for="(v,k) in pbclinkdialogitems" :key="k" class="hoverable">
                    <div class="d-flex align-start py-1 px-2">
                      <span class="mr-1">
                        <v-tooltip left content-class="tooltip-left" v-if="v.stage === -1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="">mdi-cancel</v-icon>
                          </template>
                          <span>Cancelled Request</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="info">mdi-timer-sand</v-icon>
                          </template>
                          <span>Waiting Response</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="warning">mdi-timer-sand</v-icon>
                          </template>
                          <span>Pending Approval</span>
                        </v-tooltip>
                        <v-tooltip left content-class="tooltip-left" v-else-if="v.stage === 2">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                          </template>
                          <span>Approved Request</span>
                        </v-tooltip>
                      </span>
                      <div>{{ v.description }} </div>
                      <v-spacer></v-spacer>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import pbc from '../../pbc/views/ProjectView';

export default {
  name: 'tds_ar_view',
  // props: ['props'],
  data: function(){
    return {
      generateddescription: "",
      metadata: {},
      tb: [],
      tbtotals: {},
      checklist: [],
      taglist: [],
      summary: {},
      pbc: {},
      fileprocessing: {},
      fileprocessingpercent: {},
      stagefilter: [0,1,2],
      userid: "",
      statushelpdialog: false,
      multiselectdata: [],
      commentsdialog: false,
      commentsloading: false,
      conversations: [],
      conversationtext: "",
      conversationtexterror: "",

      // analysis: {},
      // filesdata: {},
      // isreviewer: false,
      // fileuploaddata: {},
      // filesconfigdata: {},
      // rundetails: {},
      // selectedresulttab: "Summary",
      // annexuredata: {},
      // annexurechartdata: {},
      // currentannexuredata: [],
      // chartobject: null,
      // enablechart: false,

      stage: -1,      
      files: {},
      fileprocessstatus: {},
      fileprocesserrors: {},
      fileuploadpercent: {},
      allfileuploaded: false,
      running: false,
      
      isaddedit: true,
      inputparametersdata: {},
      id: "",
      tab: "",
      loading: false,
      counter: 0,

      checklistfile: null,
      checklistdialog: false,
      checklistloading: false,
      checklisterrorflag: false,
      checklistuploaddata: [],
      checklistdataerror: [],
      // categorylist: {},
      
      tbfile: null,
      tbdialog: false,
      tbloading: false,
      tberrorflag: false,
      tbuploaddata: [],
      tbdataerror: [],
      tbtableheader: [
        { text: 'Tag', value: 'tag' },
        { text: 'GL Code', value: 'gl_code' },
        { text: 'Name', value: 'name' },
        { text: 'PY Amount', value: 'py_amount' },
        { text: 'Transaction', value: 'transaction' },
        { text: 'Audit Entries', value: 'audit_entries' },
        { text: 'CY Amount', value: 'cy_amount' },
      ],
      
      pbcfile: null,
      pbcdialog: false,
      pbcloading: false,
      pbcerrorflag: false,
      pbcuploaddata: [],
      pbcdataerror: [],
      pbclinkdialog: false,
      pbclinkdialogitems: [],
      pbclinkdialogchecklistdata: {},
    }
  },
  components: {
    pbc
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
 

  methods: {
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.multiselectdata = [];
      this.getData();
    },
    getData() {
      this.loading = true;
     
      return this.axios.post("/v2/bookclosure/analysis/getdetails/"+this.id).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0] || {}
          this.metadata = data.metadata || {};
          this.stage = this.metadata.stage;
          this.tb = data.tb || [];
          this.tbtotals = {};
        for (const i of this.tb) {
           this.tbtotals[i.tag] = (this.tbtotals[i.tag] || 0) + i.cy_amount;
        }

          this.checklist = data.checklist || [];
          for (const x of this.checklist) {
            this.taglist.push(x.tag);
          }
          this.summary = data.summary || {};
          this.fileprocessstatus = {};
          this.fileprocesserrors = {};
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    readChecklistFile(key){
      let file = this.checklistfile;
      if((file || {}).name && (this.fileprocessing[key] || 0) === 0){
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["xlsx","xls","xlsm","xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file);
        }).then(dt => {
          if(JSON.stringify(dt[0] || []) === JSON.stringify(['Tag', 'Description', 'Category', 'Sub Category','Preparer','Approver'])){
            let grouplist = [];
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let gname = (el[0] || "").toString();
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
              }
            }
            let groupdata = {};
            let grouporder = [];
            let errordata = {};
            this.checklisterrorflag = false;
            let gstartnum = 1;
            let taglist = [];
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let gname = (i).toString();
                if(gname === ""){
                  let newgname = "g"+(gstartnum || "").toString();
                  while(grouplist.indexOf(newgname) !== -1) {
                    newgname = "g"+(gstartnum || "").toString();
                    gstartnum = gstartnum+1;
                  }
                  gname = newgname;
                  if(grouplist.indexOf(gname) === -1) grouplist.push(gname);
                }
                groupdata[gname] = groupdata[gname] || [];
                groupdata[gname].push({"group": gname, "tag": el[0], "description": el[1], "category": el[2], "subcategory": el[3], "preparer": (el[4] || "").split(",").filter(x => x), "approver": (el[5] || "").split(",").filter(x => x), "type": el[6]});
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
                if(grouporder.indexOf(gname) === -1) grouporder.push(gname)
                errordata[gname] = errordata[gname] || [];
                let errors = ["","","",""];
                let tagtext = (el[0] || "").toString();
                if(tagtext === "") errors[0] = "Tag is required";
                else{
                  if(taglist.indexOf(tagtext) > -1) errors[0] = "Duplicate tag found";
                  else taglist.push((el[0] || "").toString());
                }
                // else if(groupdata[gname].length > 0) {
                //   if((el[0] || "").toString() !== groupdata[gname][0].category) errors[0] = "Group items should have same category";
                // }
                if((el[1] || "").toString() === "") errors[1] = "Description is required";
                if((el[2] || "").toString() === "") errors[2] = "Category is required";
                if((el[3] || "").toString() === "") errors[3] = "Sub Category is required";
                if(errors.join("") !== "") this.checklisterrorflag = true;
                errordata[gname].push(errors);
              }
            }
            this.checklistuploaddata = [];
            this.checklistdataerror = [];
            for (const i of grouporder) {
              this.checklistuploaddata.push({'group': i, data: groupdata[i]})
               this.checklistdataerror.push(errordata[i]);
            }
            this.checklistdialog = true;
          }else throw Error("Columns 'Tag','Description','Category','Sub Category','Preparer','Approver' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.checklistfile = null;
          this.fileprocessing[key] = 0;
        });
      }
    },
    uploadChecklistData(){
      this.checklistloading = true;
      let ndt = [];
      for (const i of this.checklistuploaddata) {
        ndt.push(i.data[0]);
      }
      this.axios.post("/v2/bookclosure/analysis/bulkaddchecklist/"+this.id, {data: ndt}).then(dt => {
        if(dt.data.status === "success"){
          this.checklistdialog = false;
          this.refreshData();
          this.$store.commit("sbSuccess", "Checklist uploaded successfully");
        }else throw dt.data.message || "Error uploading Checklist";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.checklistloading = false;
      })
    },
    readPbcFile(key){
      let file = this.pbcfile;
      if((file || {}).name && (this.fileprocessing[key] || 0) === 0){
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["xlsx","xls","xlsm","xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file);
        }).then(dt => {
          if(JSON.stringify(dt[0] || []) === JSON.stringify(['Checklist Tag', 'Description', 'Info Owner Name', 'Info Owner Email','File Upload','File Upload Link','Urgent','Due Date'])){
            let grouplist = [];
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                let gname = (i).toString();
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
              }
            }
            let groupdata = {};
            let grouporder = [];
            let errordata = {};
            this.pbcerrorflag = false;
            let gstartnum = 1;
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let gname = (i).toString();
                if(gname === ""){
                  let newgname = "g"+(gstartnum || "").toString();
                  while(grouplist.indexOf(newgname) !== -1) {
                    newgname = "g"+(gstartnum || "").toString();
                    gstartnum = gstartnum+1;
                  }
                  gname = newgname;
                  if(grouplist.indexOf(gname) === -1) grouplist.push(gname);
                }
                groupdata[gname] = groupdata[gname] || [];
                let pushdata = {"group": gname, "tag": (el[0] || "").split(",").filter(x => x), "description": el[1], "info_owner_name": el[2], "info_owner_email": el[3], "file_upload": el[4], "file_upload_link": el[5], "urgent": el[6], "due_date": el[7]};
                pushdata.urgent = (pushdata.urgent === "Yes")?true:false;
                pushdata.type = (pushdata.file_upload === "Yes")?(((pushdata.file_upload_link || "") === "")?"fileupload":"fileuploadtolink"):"text";
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
                if(grouporder.indexOf(gname) === -1) grouporder.push(gname)
                errordata[gname] = errordata[gname] || [];
                let errors = ["","",""];
                let categorylist = {};

                for (const i of this.checklist) {
                  categorylist[i.tag] = i.category;
                }
                let tags = pushdata.tag;
                if(tags.length > 1) pushdata.category = "Multiple";
                else if(tags.length === 1) pushdata.category = categorylist[tags[0]];
                groupdata[gname].push(pushdata);
                
                for (const x of tags) {
                  if(this.taglist.indexOf(x) === -1) errors[0] = "Tag '"+x+"' not found in checklist";
                }
              
                if((el[1] || "").toString() === "") errors[1] = "Description is required";
                if((el[2] || "").toString() === "") errors[2] = "Info Owner Name is required";
                if((el[3] || "").toString() === "") errors[3] = "Info Owner Email is required";
                if(errors.join("") !== "") this.pbcerrorflag = true;
                errordata[gname].push(errors);
              }
            }
            this.pbcuploaddata = [];
            this.pbcdataerror = [];
            for (const i of grouporder) {
               this.pbcuploaddata.push({'group': i, data: groupdata[i]})
              this.pbcdataerror.push(errordata[i]);  
            }

            this.pbcdialog = true;
          }else throw Error("Columns 'Checklist Tag','Description','Info Owner Name','Info Owner Email','File Upload','File Upload Link','Urgent','Due Date' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.pbcfile = null;
          this.fileprocessing[key] = 0;
        });
      }
    },
    uploadPbcData(){
      this.pbcloading = true;
      let ndt = [];

      for (const i of this.pbcuploaddata) {
         ndt.push(i.data[0]);
      }
      this.axios.post("/v2/bookclosure/analysis/bulkaddpbc/"+this.id, {data: ndt}).then(dt => {
        if(dt.data.status === "success"){
          this.pbcdialog = false;
          this.refreshData();
          this.$store.commit("sbSuccess", "Information Workflow uploaded successfully");
        }else throw dt.data.message || "Error uploading Information Workflow";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.pbcloading = false;
      })
    },
    readTBFile(key){
      let file = this.tbfile;
      if((file || {}).name && (this.fileprocessing[key] || 0) === 0){
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if(["xlsx","xls","xlsm","xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file);
        }).then(dt => {
          if(JSON.stringify(dt[0] || []) === JSON.stringify(['Tag', 'GL Code', 'Name', 'PY Amount','Transaction','Audit Entries','CY Amount'])){
            let grouplist = [];
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let gname = (el[0] || "").toString();
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
              }
            }
            let groupdata = {};
            let grouporder = [];
            let errordata = {};
            this.tberrorflag = false;
            let gstartnum = 1;
            for (let i = 0; i < dt.length; i++) {
              if(i > 0){
                const el = dt[i];
                let gname = ("").toString();
                if(gname === ""){
                  let newgname = "g"+(gstartnum || "").toString();
                  while(grouplist.indexOf(newgname) !== -1) {
                    newgname = "g"+(gstartnum || "").toString();
                    gstartnum = gstartnum+1;
                  }
                  gname = newgname;
                  if(grouplist.indexOf(gname) === -1) grouplist.push(gname);
                 
                }
                groupdata[gname] = groupdata[gname] || [];
                groupdata[gname].push({"group": gname, "tag": el[0], "gl_code": el[1], "name": el[2], "py_amount": el[3], "transaction": el[4], "audit_entries": el[5], "cy_amount": el[6]});
                if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
                if(grouporder.indexOf(gname) === -1) grouporder.push(gname)
                errordata[gname] = errordata[gname] || [];
                let errors = ["","",""];
                let tag = (el[0] || "").toString();
                if(tag === "") errors[0] = "Tag is required";
                else if(this.taglist.indexOf(tag) === -1) errors[0] = "Tag not found in checklist";
                if((el[1] || "").toString() === "") errors[1] = "GL Code is required";
                if((el[2] || "").toString() === "") errors[2] = "Name is required";
                if(errors.join("") !== "") this.tberrorflag = true;
                errordata[gname].push(errors);
              }
            }
            this.tbuploaddata = [];
            this.tbdataerror = [];
            
            for (const i of grouporder) {
              this.tbuploaddata.push({'group': i, data: groupdata[i]})
              this.tbdataerror.push(errordata[i]);  
            }
            this.tbdialog = true;
          }else throw Error("Columns 'Tag','Name','Description','PY Amount','Transaction','Audit Entries','CY Amount' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.tbfile = null;
          this.fileprocessing[key] = 0;
        });
      }
    },
    uploadTBData(){
      this.tbloading = true;
      let ndt = [];
    for (const i of this.tbuploaddata) {
       ndt.push(i.data[0]);
    }

      this.axios.post("/v2/bookclosure/analysis/bulkaddtb/"+this.id, {data: ndt}).then(dt => {
        if(dt.data.status === "success"){
          this.tbdialog = false;
          this.refreshData();
          this.$store.commit("sbSuccess", "TB uploaded successfully");
        }else throw dt.data.message || "Error uploading Trial Balance";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.tbloading = false;
      })
    },
    changeChecklistStatus(analysis, type) {
      this.loading = true;
      this.axios.post("/v2/bookclosure/analysis/changecheckliststage/"+this.metadata._id+"/a/"+analysis, {data: [{type: type}]}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Status Updated");
          this.refreshData();
        }else throw dt.data.message || "Error changing status";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    closeProject() {
      this.loading = true;
      this.axios.post("/v2/tds/ar/project/close/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Closed");
          this.refreshData();
        }else throw new Error ("Error closing project");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    populatepbccount(dt = {}){
      this.pbc = dt.items || {};
      let refsummary = dt.referencesummary || {};
      for (const i of this.checklist) {
        i.__referencesummary = refsummary[i.tag];
      }
      this.checklist.push({});
      this.checklist.pop();
    },

    populatepbclinkdialogitems(data){
      let items = []
      for (const k in this.pbc) {
        if (Object.hasOwnProperty.call(this.pbc, k)) {
          const el = this.pbc[k];
          if((el.reference || []).indexOf(data.tag) > -1) items.push(el);
        }
      }
      this.pbclinkdialogitems = items;
      this.pbclinkdialogchecklistdata = data;
      this.pbclinkdialog = true;
    },
    openProject(t="open") {
      this.loading = true;
      this.axios.post("/v2/tds/ar/project/open/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          if(t === "back") this.$store.commit("sbSuccess", "Project sent back to preparer");
          else this.$store.commit("sbSuccess", "Project Re-opened");
          this.refreshData();         
        }else {
          if(t === "back") throw new Error ("Error sending back");
          else  throw new Error ("Error re-opening project");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    uploadFile: function(id){
      let file = this.files[id];
      let ext = ((file || {}).name || "").split(".").pop();
      if((file || {}).name && (this.fileprocessing[id] || 0) === 0){
        this.fileprocessing[id] = 1;
        Promise.resolve().then(() => {
          if(["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx/xls/xlsm/xlsb file extension supported";
          return this.$nova.uploadFile(this.axios, file, {
            progressfunction: (e) => {
              try{
                let p = e.loaded*100/e.total;
                this.fileprocessingpercent[id] = p.toFixed(0);
                this.uploadcounter = this.uploadcounter + 0.01;
              }catch(e){
                console.log(e);
              }
            }
          })
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.fileprocessing[id] = 2;
            let data = {upload: dt.data.data[0]._id, project: this.id, checklist: id};
            return this.axios.post("/v2/bookclosure/analysis/storeworkpaper", {data: [data]});
          }else throw new "Unable to upload file";
        }).then(dt => {
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.$store.commit("sbSuccess", "File uploaded");
            this.fileprocessing[id] = 0;
            this.refreshData();
          }else  throw new "error uploading file";
        }).catch(err => {
          this.fileprocessing[id] = 0;
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.files[id] = null;
        })
      }
    },
  },
  computed: {
    formattedchecklistdata: function(){
      let dta = [];
      let catdata = {};
      for (let i = 0; i < (this.checklist || []).length; i++) {
        const el = this.checklist[i];
        let cat = el.category;
        if(this.stagefilter.indexOf(el.stage) > -1){
          catdata[cat] = catdata[cat] || [];
          catdata[cat].push(el);
        }
      }
      for (const k in catdata) {
        if (Object.hasOwnProperty.call(catdata, k)) {
          const el = catdata[k];
          dta.push({type: 'category', name: k});
          dta = [...dta, ...el];
        }
      }
      return dta;
    },
    formattedtbtabledata: function(){
      let dta = [];
      for (let i = 0; i < (this.tb || []).length; i++) {
        const el = this.tb[i];
        dta.push({tag: el.tag, gl_code: el.gl_code, name: el.name, py_amount: (el.py_amount || 0).toFixed(0), transaction: (el.transaction || 0).toFixed(0), audit_entries: (el.audit_entries || 0).toFixed(0), cy_amount: (el.cy_amount || 0).toFixed(0)});
      }
      return dta;
    },
    formattedreflist: function(){
      let dta = [];
      for (const i of (this.checklist || [])) {
        dta.push({value: i.tag, name: i.description});
      }
      return dta;
    },
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    },
    "addeditproject.from": function(){
      this.generateDescription();
    },
    "addeditproject.to": function(){
      this.generateDescription();
    },
    "$route": function(d){
      if(d.hash === "#add") {
        this.addprojectdialog = true;
        this.addeditproject = {};
        this.addeditproject.reminderdays = this.addeditproject.reminderdays ?? 7;
      }
    },
    "addprojectdialog": function(d){
      if(!d) this.$nova.gotoLink({hash: ''});
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-tabs .v-tab {
  height: 40px !important;
}
</style>